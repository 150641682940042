.map-container {
  height: 600px;
  position: relative; /* Ensuring relative positioning for absolute child elements */
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 0px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 12px;
  border-radius: 4px;
  max-height: 200px; /* You can set a max height */
  overflow-y: auto; /* Allows scrolling if content is too long */
}

/* Style for the geocoder container */
.geocoder-container {
  position: absolute;
  top: 10px; /* Adjust as needed */
  left: 50%; /* Centers the container */
  transform: translateX(-50%); /* Aligns center of the element with the center of the map */
  z-index: 1;
  width: 50%; /* Adjust the width as needed */
  max-width: 400px; /* Maximum width */
}

.mapboxgl-ctrl-geocoder {
  border-radius: 4px;
  font-family: monospace;
}

.mapboxgl-ctrl-geocoder--input {
  min-height: 30px; /* Adjust the height of the input field */
}

.mapboxgl-ctrl-geocoder--button {
  min-width: 30px; /* Adjust the width of the search button */
}

.marker {
  /* background-image: url('/Users/kesegomokgosi/Downloads/project-anton/src/assets/avatar.png'); */
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
